import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {summaryConfig} from '@/src/components/Wizard/configs/summary.config';
import {
  LanguageCostType,
  ProjectTypeConfigType,
  QuoteType,
  SummaryRecordType,
} from '@/src/interfaces/types/wizard.types';
import {WizardProjectType} from '@/src/types/Wizard/types';
import {capitalize} from '@/src/utils/stringActions';

import CONSTS from './WizardViewConsts';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const {SERVICE_CERTIFICATE_EDITING, SERVICE_EDITING_TEXT, TRANSLATE_FROM, SELECT_LANGUAGE} = CONSTS;
const {TRANSCRIPTION, TRANSLATION, PROOFREADING} = WizardProjectType;

export const allowedWizardTypes: WizardProjectType[] = [TRANSLATION, PROOFREADING, TRANSCRIPTION];

export enum ContentTypeEnum {
  FILES = 'FILES',
  TEXT = 'TEXT',
}

export enum FilesTranslationVariantEnum {
  AS_IS = 'AS_IS',
  CUSTOM = 'CUSTOM',
}

export interface PickerCroppedData {
  cropArea: {
    position: [number, number];
    size: [number, number];
  };
  originalImageSize: [number, number];
}

export declare enum RotateDirection {
  cw = 'CW',
  ccw = 'CCW',
}
export interface PickerRotatedData {
  value: number;
  direction: RotateDirection;
}

export interface PickerFileMetadata {
  container?: string;
  cropped?: PickerCroppedData;
  filename: string;
  handle: string;
  key?: string;
  mimetype: string;
  originalFile?: object | File;
  originalPath: string;
  rotated?: PickerRotatedData;
  size: number;
  source: string;
  status?: string;
  uploadId: string;
  url: string;
  alt: string;
}

export const getFileUploadData = (data: PickerFileMetadata, index: number, context = 'source'): FormData => {
  const fd = new FormData();
  const prefix = `filePicker[${index}]`;
  Object.entries(data).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        fd.append(`${prefix}[${key}][${nestedKey}]`, String(nestedValue));
      });
    } else {
      fd.append(`${prefix}[${key}]`, String(value));
    }
  });
  fd.append(`${prefix}[context]`, context);
  return fd;
};

const getSummaryContent = ({type, languages, targetLanguage, total}: QuoteType): SummaryRecordType[] => {
  switch (type) {
    case 'translation':
      const records: SummaryRecordType[] | undefined = languages?.map(
        ({languageLabel, price: value}: LanguageCostType) => ({
          label: `Translation to ${languageLabel}`,
          value: value,
        })
      );

      return records || [];
    case 'proofreading':
      return [{label: `${targetLanguage} proofreading`, value: total}];
    case 'transcription':
      return [{label: `${targetLanguage} transcription`, value: total}];
    default:
      return [];
  }
};

const getFieldValue = (value, format: string) =>
  format === 'date' ? dayjs.unix(value).tz('GMT').format('MMMM Do, HH:mm [GMT]') : value;

export const getData = (data: QuoteType): SummaryRecordType[][] => {
  const config = summaryConfig[data.type];
  if (!config) {
    return [];
  }

  const valuesList: SummaryRecordType[][] = config.reduce(
    (list: SummaryRecordType[][], configGroup: ProjectTypeConfigType[]) => {
      const items: SummaryRecordType[] = configGroup.reduce(
        (groupList: SummaryRecordType[], {field, ...rest}: ProjectTypeConfigType) => {
          const rowValue = data?.[field];
          const value = rowValue && rest.format ? getFieldValue(rowValue, rest.format) : rowValue;
          return value
            ? [
                ...groupList,
                {
                  value,
                  ...rest,
                },
              ]
            : groupList;
        },
        []
      );

      return [...list, items];
    },
    []
  );

  const content: SummaryRecordType[] = getSummaryContent(data);
  content.forEach((record) => (record.isCurrency = true));
  valuesList[1] = content;

  return valuesList;
};

type ValidType = (typeof allowedWizardTypes)[number];

export const isValidWizardType = (type?: string | string[]): type is ValidType =>
  typeof type === 'string' && allowedWizardTypes.includes(type as ValidType);

export enum AdditionalServiceType {
  EDITING = 'editing',
  CERTIFICATE = 'certificate',
}

const {EDITING, CERTIFICATE} = AdditionalServiceType;

export const getCheckboxTitle = (type: AdditionalServiceType) => {
  const result: Record<AdditionalServiceType, string> = {
    [EDITING]: SERVICE_EDITING_TEXT,
    [CERTIFICATE]: SERVICE_CERTIFICATE_EDITING,
  };

  return result[type] || '';
};

export interface WizardPrice {
  value: string;
  color: string;
  isCrossedOut?: boolean;
}

export interface AdditionalServiceItem {
  id: string;
  type: string;
  prices: WizardPrice[];
  tooltip: string;
}

export const additionalServices: AdditionalServiceItem[] = [
  {
    id: '0',
    type: getCheckboxTitle(EDITING),
    prices: [
      {value: '+$640', color: 'grey080', isCrossedOut: true},
      {value: '+$448', color: 'green070'},
    ],
    tooltip: '',
  },
  {
    id: '1',
    type: getCheckboxTitle(CERTIFICATE),
    prices: [{value: '+$10', color: 'grey100'}],
    tooltip: '',
  },
];

export interface SourceLanguage {
  id: number;
  code: string;
  slug: string;
  name: string;
}

export interface LanguageResponse {
  Language: ILanguagesResult;
}

export interface TargetLanguagesResponse {
  data: TargetLanguage[];
}

interface LanguageResultInstance {
  fullname: string;
  shortname: string;
  slug: string;
  group?: string;
  order?: number;
  code?: string;
}

export interface ILanguagesResult {
  grouped: {
    groups: Record<
      string,
      {
        name: string;
        flag: string;
      }
    >;
    languages: Record<string, LanguageResultInstance>;
  };
  list: SourceLanguage[];
  ungrouped: Record<string, string>;
}

export interface LanguageProps {
  code: string;
  fullname: string;
  id: number;
  name: string;
  shortname: string;
  slug: string;
  group?: string;
  order?: number;
  child?: LanguageChildProps[];
  availability?: string;
  msg?: string;
}

export interface TargetLanguageProps {
  availability: string;
  group?: string | null;
  child?: TargetLanguageProps[];
  id: string | number;
  msg: string;
  name: string;
  slug: string;
}

interface LanguageChildProps {
  fullname: string;
  name: string;
  group?: string;
  order?: number;
  shortname: string;
  slug: string;
  id: number;
  code: string;
}

export interface GroupProps {
  name: string;
  child: LanguageProps[];
}

export type GroupedLanguageProps = Record<string, GroupProps>;
export type GroupedTargetLanguageProps = Record<string, GroupedTargetProps>;
export interface GroupedTargetProps {
  name: string;
  child: TargetLanguageProps[];
}
export const languageNameTransformation = (name: string, group: string) => {
  const withoutBrackets = name.replace(/[()]/g, '');
  const groupName = group.charAt(0).toUpperCase() + group.slice(1);

  return (
    withoutBrackets === groupName
      ? withoutBrackets
      : withoutBrackets.replace(group.charAt(0).toUpperCase() + group.slice(1), '')
  ).trim();
};

export const formSourceLanguages = (languages: ILanguagesResult | null) => {
  const search = '';
  const groupedSourceLanguages: LanguageProps[] = [];
  const unGroupedSourceLanguages: LanguageProps[] = [];
  const searchText: string = search?.toLowerCase();
  const grouped = languages?.grouped?.languages;

  grouped &&
    Object.values(grouped).forEach((item) => {
      const {id, code} = languages?.list.find(({slug}) => item.slug === slug) || {};

      if (id && code) {
        const option: LanguageProps = {...item, id, code, name: item.fullname};
        item.group ? groupedSourceLanguages.push(option) : unGroupedSourceLanguages.push(option);
      }
    });

  const updatedGroupedSourceLanguages: GroupProps[] = Object.values(
    groupedSourceLanguages.reduce((acc: GroupedLanguageProps, cur: LanguageProps) => {
      const {group} = cur;

      if (group) {
        acc[group] = acc[group] || {
          name: capitalize(group),
          child: [],
        };

        acc[group].child.push(cur);
      }

      return acc;
    }, {})
  );

  updatedGroupedSourceLanguages.forEach(({child}) => {
    child?.length === 1 && unGroupedSourceLanguages.push(child[0]);
  });

  const noSingleGroupedSourceLanguages = updatedGroupedSourceLanguages.filter(({child}) => child && child?.length > 1);

  const filteredSourceLanguages = [
    ...unGroupedSourceLanguages.sort((a, b) => (a.name < b.name ? -1 : 1)),
    ...noSingleGroupedSourceLanguages,
  ]
    .filter(({name, child}) =>
      child?.length
        ? child?.some((item) => item.name.toLowerCase().indexOf(searchText) !== -1)
        : name.toLowerCase().includes(searchText)
    )
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  return filteredSourceLanguages;
};

export const formTargetLanguages = (
  allTargetLanguages: TargetLanguage[],
  sourceLanguage?: LanguageProps
): (TargetLanguageProps | GroupedTargetProps)[] => {
  const groupedTargetLanguages: TargetLanguageProps[] = [];
  const unGroupedTargetLanguages: TargetLanguageProps[] = [];

  allTargetLanguages
    .filter(({slug}) => slug !== sourceLanguage?.slug)
    .forEach((item: TargetLanguageProps) => {
      item.group ? groupedTargetLanguages.push(item) : unGroupedTargetLanguages.push(item);
    });

  const updatedGroupedTargetLanguages = Object.values(
    groupedTargetLanguages.reduce((acc: GroupedTargetLanguageProps, cur: TargetLanguageProps) => {
      const {group} = cur;

      if (group) {
        acc[group] = acc[group] || {
          name: capitalize(group),
          child: [],
        };

        acc[group].child.push(cur);
      }

      return acc;
    }, {})
  );

  updatedGroupedTargetLanguages.forEach(({child}) => {
    child?.length === 1 && unGroupedTargetLanguages.push(child[0]);
  });

  const noSingleGroupedTargetLanguages = updatedGroupedTargetLanguages.filter(({child}) => child && child?.length > 1);

  const filteredTargetLanguages = [
    ...noSingleGroupedTargetLanguages,
    ...unGroupedTargetLanguages.sort((a, b) => (a.name < b.name ? -1 : 1)),
  ];

  return filteredTargetLanguages;
};

export interface TargetLanguage {
  name: string;
  slug: string;
  id: number;
  availability: string;
  msg: string;
}

export enum LanguagesSortEnum {
  TOP_USED = 'TOP_USED',
  ALPHABET = 'ALPHABET',
}

export const languagesSortOptions = [
  {
    value: LanguagesSortEnum.TOP_USED,
    label: 'Top used',
  },
  {
    value: LanguagesSortEnum.ALPHABET,
    label: 'Alphabet',
  },
];

export const translateFromNameMap = {
  [WizardProjectType.TRANSLATION]: TRANSLATE_FROM,
  [WizardProjectType.PROOFREADING]: SELECT_LANGUAGE,
  [WizardProjectType.TRANSCRIPTION]: SELECT_LANGUAGE,
};
