import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {fetchPriceQuote} from '@/src/api/wizard';
import {
  ContentTypeEnum,
  FilesTranslationVariantEnum,
  LanguageProps,
  PickerFileMetadata,
  TargetLanguage,
} from '@/src/components/Wizard/WizardViewUtils';
import {StoreDefaultTypes} from '@/src/interfaces/types/store.types';

export interface WizardData {
  contentType: ContentTypeEnum;
  totalWordCount: string;
  fileTranslationVariant?: FilesTranslationVariantEnum | null;
  text?: string | null;
  files?: FileData[] | null;
  topic: string;
  sourceLanguage?: LanguageProps;
  targetLanguages?: TargetLanguage[];
  brief?: string;
  toneOfVoice?: number;
  briefFiles: PickerFileMetadata[];
}

export interface IPriceQuote {
  price: number;
}

export interface FileData {
  uuid: string;
  filename: string;
  wordcount: number;
  type: string;
  is_scanned: boolean;
  size: number;
  mimeType: string;
}

export interface IFileWordCountChange {
  uuid: string;
  wordCount: number;
}

export interface IWizardState extends StoreDefaultTypes {
  data: WizardData;
  priceQuote?: IPriceQuote;
}

const initialState: IWizardState = {
  data: {
    contentType: ContentTypeEnum.FILES,
    totalWordCount: '0',
    fileTranslationVariant: FilesTranslationVariantEnum.AS_IS,
    topic: '0',
    briefFiles: [],
  },
  loading: false,
};

export const getPriceQuote = createAsyncThunk<IPriceQuote, WizardData>(
  'wizard/getPriceQuote',
  async (data: WizardData, {rejectWithValue}) => {
    try {
      const result: IPriceQuote = await fetchPriceQuote(data);
      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Unknown error in getPriceQuote occured');
    }
  }
);

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setContentType(state, {payload}: PayloadAction<ContentTypeEnum>) {
      state.data.contentType = payload;
    },
    setTotalWordCount(state, {payload}: PayloadAction<string>) {
      state.data.totalWordCount = payload;
    },
    setFileTranslationVariant(state, {payload}: PayloadAction<FilesTranslationVariantEnum>) {
      state.data.fileTranslationVariant = payload;
    },
    setWizardText(state, {payload}: PayloadAction<string>) {
      state.data.text = payload;
    },
    addFile(state, {payload}: PayloadAction<FileData>) {
      const files = state.data.files || [];
      state.data.files = [...files, payload];
    },
    removeFile(state, {payload}: PayloadAction<string>) {
      const oldFiles: FileData[] = state.data.files || [];
      const newFiles: FileData[] = oldFiles.filter(({uuid}) => uuid !== payload);
      state.data.files = newFiles.length ? newFiles : null;
    },
    changeFileWordCount(state, {payload}: PayloadAction<IFileWordCountChange>) {
      const oldFiles: FileData[] = state.data.files || [];
      const file = oldFiles.find(({uuid}) => uuid === payload.uuid);
      if (!file || !state.data.files?.length) {
        return;
      }
      file.wordcount = payload.wordCount;
      state.data.files = [...oldFiles];
    },
    changeTopic(state, {payload}: PayloadAction<string>) {
      state.data.topic = payload;
    },
    changeSourceLanguage(state, {payload}: PayloadAction<LanguageProps | undefined>) {
      state.data.sourceLanguage = payload;
      state.data.targetLanguages = undefined;
    },
    setTargetLanguages(state, {payload}: PayloadAction<TargetLanguage[] | undefined>) {
      state.data.targetLanguages = payload;
    },
    setBriefText(state, {payload}: PayloadAction<string>) {
      state.data.brief = payload;
    },
    setToneOfVoice(state, {payload}: PayloadAction<number>) {
      state.data.toneOfVoice = payload;
    },
    addBriefFile(state, {payload}: PayloadAction<PickerFileMetadata>) {
      state.data.briefFiles = [...state.data.briefFiles, payload];
    },
    removeBriefFile(state, {payload}: PayloadAction<string>) {
      state.data.briefFiles = state.data.briefFiles.filter(({key}) => key !== payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceQuote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPriceQuote.fulfilled, (state, {payload}: PayloadAction<IPriceQuote>) => {
        state.priceQuote = payload;
        state.loading = false;
      })
      .addCase(getPriceQuote.rejected, (state, {payload}: PayloadAction<any>) => {
        state.loading = false;
        state.error = payload || 'Error loading price quote';
      });
  },
});

export const {
  setContentType,
  setTotalWordCount,
  setFileTranslationVariant,
  setWizardText,
  addFile,
  removeFile,
  changeFileWordCount,
  changeTopic,
  changeSourceLanguage,
  setTargetLanguages,
  setBriefText,
  setToneOfVoice,
  addBriefFile,
  removeBriefFile,
} = wizardSlice.actions;
export default wizardSlice.reducer;
