import {FC, ReactElement, ReactNode, useMemo} from 'react';

import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, {SelectChangeEvent, SelectProps} from '@mui/material/Select';
import styled from 'styled-components';

import ArrowDown from '@/assets/icons_refactor/Common/small-arrow.svg';
import {font_large} from '@/theme/fonts';

import BaseIcon from './BaseIcon';

export interface OptionsProps {
  value: string | number;
  label: string | number;
  icon?: ReactNode;
  disabled?: boolean;
  endAdornment?: ReactNode;
}

interface Props extends Omit<SelectProps, 'value' | 'defaultValue'> {
  options: OptionsProps[];
  onChange?: (e: SelectChangeEvent) => void;
  value?: string | number | null;
  defaultValue?: string;
  emptyListItemContent?: string;
  dropdownIcon?: ReactNode;
  isOpen?: boolean;
  dropdownClassname?: string;
  selectAttribute?: string;
  handleClick?: VoidFunction;
  displayEmpty?: boolean;
}

const BaseSingleSelect: FC<Props> = ({
  defaultValue,
  options,
  value,
  onChange,
  isOpen,
  dropdownClassname,
  dropdownIcon,
  selectAttribute,
  handleClick,
  displayEmpty,
  ...other
}) => {
  const input = useMemo(
    () => <OutlinedInput classes={{notchedOutline: 'select-input', focused: 'select-focused'}} />,
    []
  );

  const selectClasses = useMemo(
    () => ({
      root: dropdownClassname ? dropdownClassname : undefined,
      select: 'select-root',
    }),
    [dropdownClassname]
  );

  return (
    <Wrapper
      {...(other as any)}
      open={isOpen}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue || undefined}
      input={input}
      classes={selectClasses}
      IconComponent={(props: ReactElement) =>
        dropdownIcon || <CaretIcon icon={ArrowDown} {...props} boxW={20} boxH={20} />
      }
      data-qa-auto={selectAttribute}
      onClick={handleClick}
      displayEmpty={displayEmpty}
    >
      {options.map(({value: optionValue, label, icon, disabled, endAdornment}: OptionsProps) => (
        <Item key={`${label}_${optionValue}`} value={optionValue} $icon={icon ? 1 : 0} disabled={disabled}>
          {icon && icon}
          {label}
          {endAdornment && endAdornment}
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Select)`
  background-color: ${({theme}) => theme.colors.grey000};

  & .select-input {
    border: none;
  }

  & .select-focused,
  & .select-root {
    background-color: ${({theme}) => theme.colors.grey000};
  }

  & .select-root {
    & svg {
      margin-right: 10px;
    }
  }

  div.MuiSelect-select {
    padding-right: 50px;
  }
`;

const Item = styled(MenuItem)<{$icon?: number}>`
  display: flex;
  align-items: center;
  ${font_large};

  & svg {
    margin-right: ${({$icon}) => ($icon ? '10px' : '0')};
  }
`;

const CaretIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey080};
  margin-left: 10px;
  top: 10px;
  width: 28px;
  height: 28px;
  border: none;

  svg {
    max-height: unset;
    max-width: unset;
    width: 28px;
    height: 28px;
  }
`;

export default BaseSingleSelect;
