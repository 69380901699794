export default {
  HEADER_TITLE: 'The BLEND Wizard',
  HEADER_BTN_TEXT: 'Reset',
  UPLOAD_BLOCK_TITLE: "Let's upload your content",
  UPLOAD_BLOCK_FILE_TYPES: 'Supported file types',
  UPLOAD_BLOCK_KNOW_WC: 'Get to know our word count system',
  UPLOAD_BLOCK_WC_TITLE: 'Some mocked text is here!',
  UPLOAD_BLOCK_WC_TOTAL: 'Total word count',
  UPLOAD_BLOCK_FILE_WC: 'Word Count',
  UPLOAD_BLOCK_CUSTOM_VARIANT:
    'Select this option if your files contain additional content that is not intended for translation (instructions, hidden text, filtered columns or rows, HTML/VBA code, dropdown buttons) or if you want to receive a bilingual file.',
  UPLOAD_BLOCK_AS_IS_VARIANT:
    'Select this option to translate the entire document. Make sure to exclude any non-translatable text or instructions to avoid an extra charge.',
  UPLOAD_BLOCK_SIDEBAR_WIDTH: '140px',
  FILE_TRANSLATION_FROM_SOURCES: ['local_file_system', 'googledrive', 'box'],
  FILE_TRANSLATION_AS_IS_TITLE: 'My entire document can be translated as-is',
  FILE_TRANSLATION_CUSTOM_TITLE: 'My document contains content that should not be translated ($0.00 extra)',
  TEXT_INPUT_PLACEHOLDER: 'Type or paste your text here',
  LEARN_MORE: 'Learn more',
  ADDITIONAL_TEXT: 'Additional Services',
  SERVICE_EDITING_TEXT: 'Professional editing',
  SERVICE_CERTIFICATE_EDITING: 'Certificate of Accuracy',
  EDITING_TOOLTIP: 'A professional editor will review and edit your translation for guaranteed perfection.',
  CERTIFICATE_TOOLTIP: '',
  SUMMARY_TITLE: 'Order Summary',
  TOTAL_WC: 'Total word count',
  EST_DELIVERY: 'Estimated delivery',
  ORDER_TOTAL: 'Order Total',
  TOTAL_MEDIA: 'Total length of media',
  REVIEW_ORDER: 'Review your order',
  TRANSLATION_TYPE_TEXT:
    'Convert written text from one language to another while maintaining meaning, tone and context',
  PROOFREADING_TYPE_TEXT: 'Get written text reviewed and revised to correct errors and ensure clarity and accuracy',
  TRANSCRIPTION_TYPE_TEXT: 'Convert spoken language into written text from audio or video recordings',
  PROJECT_TYPE_TITLE: 'Select project type',
  SUPPORTED_LANGUAGES: 'Supported languages',
  WHAT_CONTENT_ABOUT: 'What is your content about?*',
  TOPIC_DESCRIPTION: 'This will help us match you with an expert translator for the best results.',
  TRANSLATE_FROM: 'Translate from:',
  SELECT_LANGUAGE: 'Select language:',
  TRANSLATE_TO: 'Translate to:',
  CHOOSE_LANGUAGE: 'Choose language',
  ALL_LANGUAGES: 'All languages',
  FILTER_LANGUAGES_PLACEHOLDER: 'Type the first language letter to quick find...',
  SAVE_LANGUAGES: 'Save languages set for future projects',
  CLEAR: 'Clear',
  APPLY: 'Apply',
  SAVE_SET: 'Save set',
  LOAD_SET: 'Load set',
  SAVE_SET_TITLE: 'Save your set',
  LOAD_SET_TITLE: 'Load your set',
  SAVE_SET_PLACEHOLDER: 'Text hint',
  SAVE_SET_AS_DEFAULT: 'Save as Default',
  SET_NAME: 'Set name',
  LOAD_SET_SUBTITLE: 'In order to choose, select a set and click on load set',
  CANCEL: 'Cancel',
  SORT_BY: 'Sort by',
  BRIEF_TITLE: 'Add your project brief',
  BRIEF_INSTRUCTIONS: [
    'Target audience',
    'Words not to translate',
    'Content goals',
    'Reference URLs',
    'Keywords and terminology',
  ],
  BRIEF_PLACEHOLDER:
    'For example: We are expanding to a new market in Spain and it is important for us that you will translate the file using Spanish day-to-day jargon while keeping the formatting the same as the original document',
  BRIEF_MAX_SYMBOLS_NUMBER: 2500,
  FROM: 'from',
  BRIEF_TONE_OF_VOICE: 'Tone of voice',
  BRIEF_TONE_DESCRIPTION: "Get the best translation by selecting your content's tone of voice",
  BRIEF_REFERENCE: 'Include reference materials',
  BRIEF_TONE_OF_VOICE_PLACEHOLDER: 'Select tone of voice',
  BRIEF_UPLOAD_FILES: 'Upload files',
  BRIEF_REFERENCE_TOOLTIP_TITLE:
    'Any more special instructions or references for your translator? Please add them here.',
  REVIEW_ORDER_BUTTON: 'Review your order',
  SELECT: 'Select',
};
